import React from "react";

import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img_moox from "../../img/mooxshop.png";
import img_al from "../../img/al.png";
import img_dikotomy from "../../img/dikotomy1.jpg";
import img_steele from "../../img/steele.jpg";
import img_sudaca from "../../img/sudaca.jpg";
import img_goFar from "../../img/gofar.jpg";
import img_ozma from "../../img/ozma.jpg";
import img_walux from "../../img/walux.jpg";
import img_svndz from "../../img/svndz.png";
import img_instaco from "../../img/instaco.png";
import img_chilly from "../../img/chilly.png";
import img_yatra from "../../img/yatra.png";
import img_bottle from "../../img/bottle.png";
import img_natshop from "../../img/natshop.png";
import img_cest from "../../img/cest.png";
import img_round from "../../img/round.png";
import img_exp from "../../img/experiment.png";
import img_redhot from "../../img/redhot.png";

import log_moox from "../../img/logs/moox-log.png";
import log_al from "../../img/logs/aire-log.png";
import log_dikotomy from "../../img/logs/dikotomy.png";
import log_steele from "../../img/logs/steele.png";
import log_sudaca from "../../img/logs/sudaca.png";
import log_goFar from "../../img/logs/logo-gofar.png";
import log_ozma from "../../img/logs/ozmalog.png";
import log_walux from "../../img/logs/waluxlog.png";
import log_svndz from "../../img/logs/svndz-log.png";
import log_instaco from "../../img/logs/instaco-log.png";
import log_chilly from "../../img/logs/chilly_log.png";
import log_yatra from "../../img/logs/logo-yatra.png";
import log_bottle from "../../img/logs/log_bot.png";
import log_natshop from "../../img/logs/logo-natshop.png";
import log_cest from "../../img/logs/cest-log.png";
import log_round from "../../img/logs/log-round.jpg";
import log_exp from "../../img/logs/log-experiment.png";
import log_redhot from "../../img/logs/log-red.png";


const Section5 = ({ data }) => {
  let { owl } = data;

  let settings = {
    dots: true,
    infinite: true,
    speed: 700,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "100px",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: "90px",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    //   {/* <!--  owl carrousel --> */}
    <section id="section-5" className="grid slid-proyects">
      <div id="change-gray">
        <div className="row">
          <div className="header-text">
            <h4 id="center-with-loop">{owl.title}</h4>
            <p>{owl.content}</p>
          </div>
          <Slider {...settings}>

          <div className="item">
              <img src={img_yatra} alt="yatra" />
              <div className="info">
                <img src={log_yatra} alt="log yatra" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Music</span>
                  </li>
                  <li>
                    Country:
                    <span>Colombia</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/yatra">sebastianyatra.com</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="item">
              <img src={img_redhot} alt="red hot chilli" />
              <div className="info">
                <img style={{maxWidth:"60px", maxHeight:"60px"}} src={log_redhot} alt="log red hot chilli" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Music</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/redhotchili">shop.redhotchilipeppers.com/</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="item">
              <img src={img_bottle} alt="bottle" />
              <div className="info">
                <img src={log_bottle} alt="log bottle" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Bottles</span>
                  </li>
                  <li>
                    Country:
                    <span>CO</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/bottle">https://thebottledepot.co/</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="item">
              <img src={img_natshop} alt="natshop" />
              <div className="info">
                <img src={log_natshop} alt="log natshop" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Personal hygiene</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/natshop">https://www.natshop.com/</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="item">
              <img src={img_cest} alt="cest bon" />
              <div className="info">
                <img src={log_cest} alt="log cestbon" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Fashion</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/cest">https://cestbon.online/</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="item">
              <img src={img_round} alt="Round two" />
              <div className="info">
                <img src={log_round} alt="log round two" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Fashion</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/roundtwo">https://roundtwostore.com/</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="item">
              <img src={img_exp} alt="experiment" />
              <div className="info">
                <img src={log_exp} alt="log experiment" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Fashion</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/experiment">https://experimentclothing.com/</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="item">
              <img src={img_chilly} alt="chilly" />
              <div className="info">
                <img src={log_chilly} alt="log chilly" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Beauty</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/chilly">www.chillymouth.com/</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="item">
              <img src={img_moox} alt="moox" />
              <div className="info">
                <img src={log_moox} alt="log moox" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Fashion</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/moox">https://shopmoox.com</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="item">
              <img src={img_al} alt="al" />
              <div className="info">
                <img src={log_al} alt="log_al" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Fashion</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/al">https://airelibreavenue.com</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="item">
              <img src={img_dikotomy} alt="dikotomy" />
              <div className="info">
                <img src={log_dikotomy} alt="log dikotomy" />
                <p>
                  Launched in 2007, with #goals of creating fresh styles in
                  premium fabrics and classic fits at a price that won’t leave
                  you low-key broke.
                </p>
                <ul>
                  <li>
                    Industry:
                    <span>Fashion</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/dikotomy">https://dikotomy.com</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="item">
              <img src={img_steele} alt="steele" />
              <div className="info">
                <img src={log_steele} alt="log steele" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Fashion</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/steele">
                      https://steeleofficial.com
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="item">
              <img src={img_sudaca} alt="sudaca" />
              <div className="info">
                <img src={log_sudaca} alt="log sudaca" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Fashion</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/sudaca">https://sudaca.com</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="item">
              <img src={img_goFar} alt="Go far fitness" />
              <div className="info">
                <img src={log_goFar} alt="log go far" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Fitness</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/goFar">https://gofarfitness.com</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="item">
              <img src={img_ozma} alt="ozma" />
              <div className="info">
                <img src={log_ozma} alt="log ozma" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Music</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/ozma">https://ozmarecords.com</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="item">
              <img src={img_walux} alt="walux" />
              <div className="info">
                <img src={log_walux} alt="log walux" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Fashion</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/walux">https://w-alux.com</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="item">
              <img src={img_svndz} alt="svndz" />
              <div className="info">
                <img src={log_svndz} alt="log svndz" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Fashion</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/svndz">https://svndz.com</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="item">
              <img src={img_instaco} alt="instaco" />
              <div className="info">
                <img src={log_instaco} alt="log instaco" />
                <p></p>
                <ul>
                  <li>
                    Industry:
                    <span>Fashion</span>
                  </li>
                  <li>
                    Country:
                    <span>USA</span>
                  </li>
                  <li>
                    Website:
                    <Link to="/portafolio/instaco">https://instaco.com</Link>
                  </li>
                </ul>
              </div>
            </div>


          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Section5;

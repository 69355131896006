import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import Slider from "react-slick";
import { useParams } from "react-router";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "./loader";

const Portafolio = ({ data }) => {
  const [loading, setLoading] = useState(true);

  let { currShop } = useParams();
  let { port } = data;
  let shop = data.port[currShop];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (data) setLoading(false);
    }, 1000);
  }, [data]);
  let settings = {
    dots: true,
    infinite: true,
    speed: 700,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "0",
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {loading && <Loader />}

      <div className="section_port">
        <div className="slider-port">
          <Slider {...settings}>
            {shop.sliders.map((e) => (
              <div key={uuidv4()} className="item">
                <a target="_blank" rel="noreferrer" href={shop.url}>
                  <img className="lazyload" src={e.imgDsktp} alt="Slider" />
                  <img className="lazyload" src={e.imgMob} alt="Slider" />
                </a>
              </div>
            ))}
          </Slider>
        </div>

        <section className="product-demo">
          <div className="demoContent">
            <h3>{shop.mainTxt}</h3>
            <a
              className="btn blackFill"
              href={shop.url}
              target="_blank"
              rel="noreferrer"
            >
              {port.visit}
            </a>
          </div>
          <div className="sec-img">
            <img className="demoImage" src={shop.imgLp} alt="The store" />
          </div>
        </section>

        <div className="image-with-text">
          <div>
            <img src={shop.secImg} alt="" />
          </div>
          <div>
            <div>
              <h3>{shop.title}</h3>
            </div>
            <div>
              <a href={port.moox.url} rel="noreferrer" target="_blank">
                {shop.url}
              </a>
            </div>
            <div>
              <a href={shop.url} target="_blank" rel="noreferrer">
                {port.visit}
              </a>
            </div>
          </div>
        </div>

        <section className="product-more">
          <img
            className="moreImage desktopVersion"
            src={shop.lastImg}
            alt="The store browser window - Desktop"
          />
          <img
            className="moreImage mobileVersion"
            src={shop.lastImg}
            alt="The store browser window - Mobile"
          />
          <div className="moreButtons">
            <a className="btn whiteWithBorder" href="/">
              {port.viewMore}
            </a>
            <a className="btn blackFill" href="/">
              {port.btnPrice}
            </a>
          </div>
        </section>
      </div>
    </>
  );
};

export default Portafolio;

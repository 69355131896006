import React, { useRef } from "react";
import Logo from "../img/Octocode_logo.svg";

import Picker from "./picker";

const Header = ({ data, idiom, getLg }) => {
  const refMenu = useRef();
  const btnOpen = useRef();

  const openMenu = (e) => {
    document.body.classList.toggle("o-hidden");

    btnOpen.current.className === ""
      ? (btnOpen.current.className = "change")
      : (btnOpen.current.className = "");

    refMenu.current.className === ""
      ? (refMenu.current.className = "open")
      : (refMenu.current.className = "");
  };

  const scrollNav = (e) => {
    e.preventDefault();
    let open = document.querySelector("#contain");
    open.click();
    let currNav = e.target.dataset.id;

    if (currNav !== "") {
      let el = document.querySelector(`.${currNav}`);
      if (el) {
        let scroll = document.querySelector(`.${currNav}`).offsetTop;
        window.scrollTo({ top: scroll, left: 0, behavior: "smooth" });
      } else {
        window.location = "/";
      }
    } else {
      window.open(e.target.dataset.href);
    }
  };

  let currScroll = 0;
  const HeaderActive = () => {
    let updtScroll = window.pageYOffset;
    if (updtScroll < currScroll) {
      document.querySelector(".container-header").classList.remove("inactive");
    } else {
      document.querySelector(".container-header").classList.add("inactive");
      if (updtScroll < 30) {
        document
          .querySelector(".container-header")
          .classList.remove("inactive");
      }
    }
    currScroll = updtScroll;

    if (updtScroll > 400) {
      document.querySelector("body").classList.add("position-x");
    } else {
      document.querySelector("body").classList.remove("position-x");
    }
  };

  window.addEventListener("scroll", HeaderActive);

  return (
    <section className="s1 container-header">
      <div id="receive-beret">
        <div>
          <p>
            {" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://api.whatsapp.com/send?phone=5215548361032"
            >
              TEL: (55) 4836 1032
            </a>{" "}
            | <a href="mailto:hola@theoctocode.com">HOLA@THEOCTOCODE.COM</a>
          </p>
          <Picker getLg={getLg} idiom={idiom} />
        </div>
      </div>
      {/* <!-- Navbar --> */}
      <nav className="navbar">
        <a className="log" href="/">
          <img src={Logo} alt="" />
        </a>
        <div className="navbar-buttons">
          <button id="contain" onClick={openMenu} ref={btnOpen}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
            <div className="bar4"></div>
          </button>
        </div>
      </nav>
      {/* <!-- Overlay Navigation --> */}
      <nav id="overlay" ref={refMenu}>
        <nav className="overlay-menu">
          <ul>
            {data.header.map((e) => (
              <li id={e.id} key={e.id}>
                <a
                  className="item-nav"
                  data-id={e.dataId}
                  href={e.href}
                  data-href={e.dataHref}
                  rel="noreferrer"
                  target={e.target}
                  onClick={scrollNav}
                >
                  {e.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </nav>
    </section>
  );
};

export default Header;

import React, { useState, useRef } from "react";
import ChevronRight from "../../img/arrow-square-right-duotone.svg";
import emailjs from "emailjs-com";

const initialForm = {
  name: "",
  email:"",
  msj: ""
};

const Section6 = ({ data }) => {
  let { contact, msjSend } = data;

  const frm = useRef();

  const [form, setForm] = useState(initialForm);
  const [sending, setSending] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexComments = /^.{1,255}$/;

    if (!form.name && !form.msj) {
      setError(msjSend.dataEmpty);
      setTimeout(() => {
        setError(null);
      }, 3000);
      return;
    } else {
      if (!regexName.test(form.name.trim())) {
        setError(msjSend.msjName);
        setTimeout(() => {
          setError(null);
        }, 3000);
        return;
      }
      if (!regexComments.test(form.msj.trim())) {
        setError(msjSend.msjText);
        setTimeout(() => {
          setError(null);
        }, 3000);
        return;
      }

      setSending(msjSend.process);
      emailjs
        .sendForm(
          "service_awgb6gn",
          "template_h66gur3",
          frm.current,
          "user_JgDGZcdXC2OzcVD8yXIpb"
        )
        .then(
          (result) => {
            setSending(msjSend.successText);
            setForm(initialForm);
            setTimeout(() => {
              setSending(null);
            }, 3000);
          },
          (error) => {
            setSending(null);
            setError(error.text);
            setTimeout(() => {
              setError(null);
            }, 3000);
          }
        );
    }
  };

  return (
    <section id="section-6" className="contact-frm grid">
      <div>
        <h2>{contact.title}</h2>
        <p>{contact.content1}</p>
        <p>{contact.content2}</p>
        <form ref={frm} onSubmit={handleSubmit} id="sending">
          <div className="two-column">
            <div>
              <label htmlFor="name">{contact.name}:</label>
              <input
                name="name"
                id="name"
                type="text"
                onChange={handleChange}
                value={form.name}
              />
            </div>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                name="email"
                id="email"
                type="text"
                onChange={handleChange}
                value={form.email}
              />
            </div>
          </div>
          <div>
            <label htmlFor="msj">{contact.msj}:</label>
            <textarea
              name="msj"
              id="msj"
              cols="30"
              rows="20"
              value={form.msj}
              onChange={handleChange}
            ></textarea>
          </div>
          {error && (
            <span style={{ color: "#f94e3d" }} id="msj-alert">
              {error}
            </span>
          )}
          {sending && (
            <span style={{ color: "#3b94f9" }} id="msj-alert">
              {sending}
            </span>
          )}
          <button id="subscribe-button" type="submit">
            <img src={ChevronRight} alt="down" />
            <i className="fas fa-arrow-square-right" aria-hidden="true"></i>
            {contact.send}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Section6;

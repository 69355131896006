import React from "react";
import { Link } from "react-router-dom";

import ecommerce_icon from "../../img/ecommerce_icon.svg";
import desarrollo_icon from "../../img/desarrollo_icon.svg";
import estrategia_icon from "../../img/estrategia_icon.svg";

const Section4 = ({ data }) => {
  let { services } = data;

  return (
    <div id="section-4" className="services-txt grid">
      <div>
        <h3>{services.titleServices}</h3>
        <p>{services.textServices}</p>
      </div>

      <div>
        <div id="card-1">
          <div>
            <h4>{services.titleDevelop}</h4>
            <p>{services.textDevelop}</p>
            <span>
              <img src={desarrollo_icon} alt="" />
            </span>
          </div>
        </div>
        <div id="card-2">
          <div>
            <h4>{services.e_commerceTitle}</h4>
            <p>{services.e_commerceText}</p>
            <span>
              <img src={ecommerce_icon} alt="" />
            </span>
          </div>
        </div>
        <div id="card-3">
          <div>
            <h4>{services.onlineTitle}</h4>
            <p>{services.onlineText}</p>
            <span>
              <img src={estrategia_icon} alt="" />
            </span>
          </div>
        </div>
      </div>
      <div className="bundles">
        <h3>{services.bundleTitle}</h3>
        <div id="card-4">
          <div>
            <h4>{services.bundleSubtitle}</h4>
            <p>{services.bundleSubtext}</p>           
            <Link to="/bundles">View more</Link>           
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section4;

import React from 'react'

const Bundles = ({ data }) => {
  let { bundles } = data;
  return (
    <div className='wrapper-bundles'>
      <div className="section-1">
        <div>
          <h1>{bundles.section1.mainTitle}</h1>
          <p>{bundles.section1.txtMain}</p>
          <a href="https://octocode-developer.myshopify.com/collections/build-a-box" target="_blank" rel="noopener noreferrer">{bundles.section1.btnMain}</a>
        </div>
        <div>
          {/* <img src="https://rechargepayments.com/wp-content/uploads/2022/07/bundles-demo.mp4" alt="octo box"/> */}
          <video autoPlay loop="" muted="" src="https://rechargepayments.com/wp-content/uploads/2022/07/bundles-demo.mp4" playsInline=""></video>
        </div>
      </div>
      <div className="benefits">
        <h2>{bundles.benefits.title}</h2>
        <div>
          <h4>{bundles.benefits.subTitle1}</h4>
          <p>{bundles.benefits.text1}</p>
        </div>
        <div>
          <h4>{bundles.benefits.subTitle2}</h4>
          <p>{bundles.benefits.text2}</p>
        </div>
        <div>
          <h4>{bundles.benefits.subTitle3}</h4>
          <p>{bundles.benefits.text3}</p>
        </div>
      </div>
      <div className="works">
        <div className="wrapper-page-width">
          <h2>{bundles.works.title}</h2>
          <ol>
            <li>
              <h4>{bundles.works.step1Title}</h4>
              <p>{bundles.works.step1Subtitle}</p>
            </li>
            <li>
              <h4>{bundles.works.step2Title}</h4>
              <p>{bundles.works.step2Subtitle}</p>
            </li>
            <li>
              <h4>{bundles.works.step3Title}</h4>
              <p>{bundles.works.step3Subtitle}</p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default Bundles
import React, { useEffect } from "react";

import Section1 from "./sections/section1";
import Section2 from "./sections/section2";
import Section3 from "./sections/section3";
import Section4 from "./sections/section4";
import Section5 from "./sections/section5";
import Section6 from "./sections/section6";

const Home = ({ data }) => {
  const bg_dark = () => {
    // console.log("dark");
    let fadeStart = 300; //El desplazamiento de 100 px o menos equivaldrá a 1 opacidad
    let fadeUntil = 500; //  Desplazamiento de 200 px o más equivaldrá a 0 opacidad
    // let fading = $(".view-city");
    let fading = document.querySelector(".view-city");
    let txt = document.querySelector(".txt-main");
    let offset = window.pageYOffset;
    let opacity = 0;

    if (offset <= fadeStart) {
      opacity = 1;
    } else if (offset <= fadeUntil) {
      opacity = 1 - offset / fadeUntil;
    }
    fading.style.opacity = opacity;
    txt.style.opacity = opacity;
  };

  const bg_ligth = () => {
    let windowHeight = window.pageYOffset;
    let slogText = document.querySelector(".slog-text").offsetTop;
    let about = document.querySelector(".container-about").offsetTop;
    let services = document.querySelector(".services-txt").offsetTop;
    let proyects = document.querySelector(".slid-proyects").offsetTop;
    let contact = document.querySelector(".contact-frm").offsetTop;
    let grid = document.getElementsByClassName("grid");

    if (windowHeight + 300 >= slogText) {
      //document.querySelector(".grid").classList.add("opacity-hidden");
      for (let i = 0; i < grid.length; i++) {
        grid[i].classList.add("opacity-hidden");
      }
      document.querySelector(".slog-text").classList.remove("opacity-hidden");
    }
    if (windowHeight + 300 >= about) {
      document.querySelector("body").classList.add("new-bg");
      for (let i = 0; i < grid.length; i++) {
        grid[i].classList.add("opacity-hidden");
      }
      document
        .querySelector(".container-about")
        .classList.remove("opacity-hidden");
    } else {
      document.querySelector("body").classList.remove("new-bg");
    }
    if (windowHeight + 200 >= services) {
      document.querySelector("body").classList.remove("new-bg");
      document.querySelector(".grid").classList.add("opacity-hidden");
      for (let i = 0; i < grid.length; i++) {
        grid[i].classList.add("opacity-hidden");
      }
      document
        .querySelector(".services-txt")
        .classList.remove("opacity-hidden");
    }
    if (windowHeight + 300 >= proyects) {
      document.querySelector("body").classList.add("new-bg");
      document.querySelector(".grid").classList.add("opacity-hidden");
      for (let i = 0; i < grid.length; i++) {
        grid[i].classList.add("opacity-hidden");
      }
      document
        .querySelector(".slid-proyects")
        .classList.remove("opacity-hidden");
    }
    if (windowHeight + 200 >= contact) {
      document.querySelector("body").classList.remove("new-bg");
      for (let i = 0; i < grid.length; i++) {
        grid[i].classList.add("opacity-hidden");
      }
      document.querySelector(".contact-frm").classList.remove("opacity-hidden");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", bg_ligth);
    window.addEventListener("scroll", bg_dark);

    return () => {
      window.removeEventListener("scroll", bg_ligth);
      window.removeEventListener("scroll", bg_dark);
      document.querySelector("body").classList.remove("new-bg");
    };
  }, []);

  return (
    <>
      <Section1 data={data} />
      <Section2 data={data} />
      <Section3 data={data} />
      <Section4 data={data} />
      <Section5 data={data} />
      <Section6 data={data} />
    </>
  );
};

export default Home;

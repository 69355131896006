import React from "react";

import ChevronDown from "../../img/chevron-down-solid.svg";
import ChevronRight from "../../img/arrow-square-right-duotone.svg";

const Section3 = ({ data }) => {
  let { aboutTxt } = data;

  const activeDown = (e) => {
    var sectionNext = e.target.dataset.menu;
    let scroll = document.querySelector(`#section-${sectionNext}`).offsetTop;
    if (sectionNext === 2) {
      window.scrollTo({ top: scroll, left: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: scroll, left: 0, behavior: "smooth" });
    }
  };

  return (
    <div id="section-3" className="container-about grid">
      <div className="about">
        <div className="line-left">
          <p data-menu="4" onClick={activeDown}>
            <img src={ChevronDown} alt="down" /> The Octocode v2020
          </p>
        </div>
        <h3>{aboutTxt.title}</h3>
        <p>{aboutTxt.content1}</p>

        <p>{aboutTxt.content2}</p>
        <div className="link-bottom down">
          <img src={ChevronRight} alt="right" />
          <span className="down" data-menu="4" onClick={activeDown}>
            {aboutTxt.moreAbout}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Section3;

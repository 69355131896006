import React from "react";

export default function Picker({ getLg, idiom }) {
  let idiomNav = navigator.language || navigator.userLanguage;

  if (idiomNav === "es-MX") {
    idiomNav = true;
  } else {
    idiomNav = false;
  }

  return (
    <div className="select-lg">
      <span className={idiom ? "active" : ""} onClick={getLg}>
        Es
      </span>{" "}
      |
      <span className={idiom ? "" : "active"} onClick={getLg}>
        {" "}
        En
      </span>
    </div>
  );
}

import React, { useEffect } from "react";

import Down from "../img/download.svg";

const ArrowDown = () => {
  const activeDown = (e) => {
    var sectionNext = e.target.dataset.menu;
    let scroll = document.querySelector(`#section-${sectionNext}`).offsetTop;
    if (sectionNext === 2) {
      window.scrollTo({ top: scroll, left: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: scroll, left: 0, behavior: "smooth" });
    }
  };

  const ejecuteScroll = (e) => {
    let sec1h = document.querySelector("#section-1").offsetHeight;
    let sec2h = document.querySelector("#section-2").offsetHeight;
    let sec3h = document.querySelector("#section-3").offsetHeight;
    let sec4h = document.querySelector("#section-4").offsetHeight;
    let sec5h = document.querySelector("#section-5").offsetHeight;
    let topNavCircle = window.pageYOffset + window.innerHeight + 200;

    if (topNavCircle < sec1h) {
    }
    if (topNavCircle > sec1h && topNavCircle < sec1h + sec2h) {
      document.querySelector(".arrow-down").style.display = "flex";
      document.querySelector("#down").dataset.menu = "3";
    } else {
      document.querySelector(".arrow-down").style.display = "flex";
      document.querySelector("#down").dataset.menu = "2";
    }
    if (topNavCircle > sec1h + sec2h && topNavCircle < sec1h + sec2h + sec3h) {
      document.querySelector(".arrow-down").style.display = "flex";
      document.querySelector("#down").dataset.menu = "4";
    }
    if (
      topNavCircle > sec1h + sec2h + sec3h &&
      topNavCircle < sec1h + sec2h + sec3h + sec4h
    ) {
      document.querySelector(".arrow-down").style.display = "flex";
      document.querySelector("#down").dataset.menu = "5";
    }
    if (
      topNavCircle > sec1h + sec2h + sec3h + sec4h &&
      topNavCircle < sec1h + sec2h + sec3h + sec4h + sec5h
    ) {
      document.querySelector(".arrow-down").style.display = "flex";
      document.querySelector("#down").dataset.menu = "6";
    }
    if (topNavCircle > sec1h + sec2h + sec3h + sec4h + sec5h) {
      document.querySelector(".arrow-down").style.display = "none";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", ejecuteScroll);
    return () => {
      window.removeEventListener("scroll", ejecuteScroll);
    };
  }, []);

  return (
    <div className="arrow-down">
      <div>
        <img id="down" src={Down} alt="" data-menu="2" onClick={activeDown} />
      </div>
    </div>
  );
};

export default ArrowDown;

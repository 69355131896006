import React, { useRef, useEffect } from "react";

import ImgCity from "../../img/vectorpaint.svg";
// import ImgCityBgPoster from "../../img/background_1.jpg";

const Section1 = ({ data }) => {
  let { mainText } = data;

  const videoRef = useRef(null);

  useEffect(() => {
    videoRef.current.defaultMuted = true;
    setTimeout(function(){
      videoRef.current.autoplay = true;
      videoRef.current.play();
    },500)
  });


  return (
    <div id="section-1" className="bg-dark">
      <div className="view-city">
        <div id="main_vd" className="video-container">
          <video ref={videoRef} loop autoPlay muted playsInline controls={false}>
              <source
                src="https://player.vimeo.com/progressive_redirect/playback/677841888/rendition/1080p?loc=external&signature=c84004c87d05533bcd6a62fc9090152e92ecb2243afc79f69d9b2e42ca7b1258"
              />
            </video>            
          </div>
        <div>
          <div className="txt-main">
            <img src={ImgCity} alt="" />
            <h1>
              {mainText.text1}
              <a href={mainText.link} target="_blank" rel="noreferrer">
                {" "}
                {mainText.txtLink}
              </a>
              {mainText.text2}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;

import React from "react";

import Phone from "../img/phone-alt-solid.svg";
import Msg from "../img/envelope-regular.svg";
import Logo from "../img/log-footer.png";

const Footer = () => {
  return (
    <footer>
      <ul>
        <li>
          <a href="Tel:554-836-1032">
            <img src={Phone} alt="phone" /> <span> 55 4836-1032 </span>
          </a>{" "}
        </li>
        <li>
          <a href="mailto:hola@theoctocode.com">
            <img src={Msg} alt="msj" /> <span>hola@theoctocode.com</span>
          </a>{" "}
        </li>
      </ul>
      <div>
        <div>
          <span>2021 Octocode</span>
        </div>
        <div>
          <img src={Logo} alt="" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
